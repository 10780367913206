import { gql } from '@apollo/client';

const bankAccountActivityHistory = (
  selectedVkns = [],
  selectedBankAccounts = [],
  startDate = '2020-02-24T23:58:19.888954',
  endDate = '2021-02-24T23:58:19.888954',
  pageNumber = 1,
  selectedBanks = [],
  description,
  useAbsoluteValues = false,
) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $selectedBankAccounts: [String]
        $startDate: DateTime
        $endDate: DateTime
        $pageNumber: Int
        $pageSize: Int
        $selectedBanks: [Int]
        $description: String
        $useAbsoluteValues: Boolean
      ) {
        bankAccountActivityQuery {
          activityHistory(
            selectedVkns: $selectedVkns
            selectedBankAccounts: $selectedBankAccounts
            startDate: $startDate
            endDate: $endDate
            pageNumber: $pageNumber
            selectedBanks: $selectedBanks
            description: $description
            useAbsoluteValues: $useAbsoluteValues
          ) {
            result {
              accountName
              accountNo
              amount
              bankCode
              bankIconLogo
              bankLogo
              bankName
              branchCode
              branchName
              currency
              date
              description
              iban
              id
              notViewed
              totalBalance
              transactionId
              vkn
            }
            totalCount
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccounts,
      startDate,
      endDate,
      pageNumber,
      selectedBanks,
      description,
      useAbsoluteValues,
    },
  };
};

export default bankAccountActivityHistory;

/*

const response = {
  "data": {
    "bankAccountActivityQuery": {
      "activityHistory": {
        "result": [
          {
            "accountName": "Demo Hesap - EUR",
            "accountNo": "D-123456781",
            "amount": "-250.00",
            "bankCode": 67,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
            "bankName": "Yapi ve Kredi Bankasi A.S.",
            "branchCode": 756,
            "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
            "currency": "EUR",
            "date": "2021-02-23T23:58:19.994909",
            "description": "3-Demo kullanıcı - Avro hesabı için oluşturulmuş fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "250.00",
            "transactionId": "f5ff0195-6b26-423a-b49b-7fec87d8c5f4",
            "vkn": "100000000"
          },
          {
            "accountName": "3 Demo Hesap - USD",
            "accountNo": "D-123456782",
            "amount": "120.00",
            "bankCode": 67,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
            "bankName": "Yapi ve Kredi Bankasi A.S.",
            "branchCode": 756,
            "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
            "currency": "USD",
            "date": "2021-02-23T23:58:19.99489",
            "description": "3 Demo kullanıcı, USD hesabı için oluşturulmuş 2. fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "150.00",
            "transactionId": "aec7c721-2a13-456f-bd38-c4fdd8d30e16",
            "vkn": "100000000"
          },
          {
            "accountName": "Demo Hesap - EUR",
            "accountNo": "D-123456784",
            "amount": "50.00",
            "bankCode": 10,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
            "bankName": "Ziraat Bankası A.Ş.",
            "branchCode": 2507,
            "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
            "currency": "EUR",
            "date": "2021-02-23T23:58:19.888991",
            "description": "2-Demo kullanıcı - Avro hesabı için oluşturulmuş fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "150.00",
            "transactionId": "d42f3ad8-5bbf-4d6c-9591-919d1766ab29",
            "vkn": "100000000"
          },
          {
            "accountName": "Demo Hesap - USD",
            "accountNo": "D-123456785",
            "amount": "-250.00",
            "bankCode": 10,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
            "bankName": "Ziraat Bankası A.Ş.",
            "branchCode": 2507,
            "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
            "currency": "USD",
            "date": "2021-02-23T23:58:19.888967",
            "description": "2 Demo kullanıcı, USD hesabı için oluşturulmuş 2. fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "2750.00",
            "transactionId": "9ad190e2-87b4-406e-8568-44f4f6bbfc8c",
            "vkn": "100000000"
          },
          {
            "accountName": "Demo Hesap - EUR",
            "accountNo": "D-123456781",
            "amount": "500.00",
            "bankCode": 67,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
            "bankName": "Yapi ve Kredi Bankasi A.S.",
            "branchCode": 756,
            "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
            "currency": "EUR",
            "date": "2021-02-22T23:58:19.994903",
            "description": "3 Demo kullanıcı - Avro hesabı için oluşturulmuş fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "500.00",
            "transactionId": "c3471640-016c-4be5-b8e8-cdc456cbda6e",
            "vkn": "100000000"
          },
          {
            "accountName": "3 Demo Hesap - TRY",
            "accountNo": "D-123456783",
            "amount": "-1238.00",
            "bankCode": 67,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
            "bankName": "Yapi ve Kredi Bankasi A.S.",
            "branchCode": 756,
            "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
            "currency": "TRY",
            "date": "2021-02-22T23:58:19.994867",
            "description": "3 Demo kullanıcı için oluşturulmuş ikinci fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "3280.00",
            "transactionId": "c428ae40-fc24-4126-aeda-0ee1d70d3e4a",
            "vkn": "100000000"
          },
          {
            "accountName": "Demo Hesap - EUR",
            "accountNo": "D-123456784",
            "amount": "100.00",
            "bankCode": 10,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
            "bankName": "Ziraat Bankası A.Ş.",
            "branchCode": 2507,
            "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
            "currency": "EUR",
            "date": "2021-02-22T23:58:19.888984",
            "description": "2 Demo kullanıcı - Avro hesabı için oluşturulmuş fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "100.00",
            "transactionId": "3bf81fe5-0b9e-4120-9b1a-019a9ccbd97f",
            "vkn": "100000000"
          },
          {
            "accountName": "Demo Hesap - TRY",
            "accountNo": "D-123456786",
            "amount": "25000.00",
            "bankCode": 10,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
            "bankName": "Ziraat Bankası A.Ş.",
            "branchCode": 2507,
            "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
            "currency": "TRY",
            "date": "2021-02-22T23:58:19.888947",
            "description": "2 Demo kullanıcı için oluşturulmuş ikinci fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "45000.00",
            "transactionId": "c58501d7-3977-404e-a0ac-b2f4b3893d11",
            "vkn": "100000000"
          },
          {
            "accountName": "3 Demo Hesap - USD",
            "accountNo": "D-123456782",
            "amount": "30.00",
            "bankCode": 67,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
            "bankName": "Yapi ve Kredi Bankasi A.S.",
            "branchCode": 756,
            "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
            "currency": "USD",
            "date": "2021-02-21T23:58:19.994883",
            "description": "3 Demo kullanıcı, USD hesabı için oluşturulmuş fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "30.00",
            "transactionId": "e15bc370-3847-41b4-9d62-21a58d7bb473",
            "vkn": "100000000"
          },
          {
            "accountName": "3 Demo Hesap - TRY",
            "accountNo": "D-123456783",
            "amount": "4518.00",
            "bankCode": 67,
            "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
            "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
            "bankName": "Yapi ve Kredi Bankasi A.S.",
            "branchCode": 756,
            "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
            "currency": "TRY",
            "date": "2021-02-21T23:58:19.994832",
            "description": "3 Demo kullanıcı için oluşturulmuş fake bir harekettir.",
            "iban": "TRTR670006200000000000000000",
            "id": 0,
            "notViewed": false,
            "totalBalance": "4518.00",
            "transactionId": "6dd2dd0e-a6ae-481a-9590-a03156f7f566",
            "vkn": "100000000"
          }
        ],
        "totalCount": 21
      }
    }
  }
}

*/
