import { gql } from '@apollo/client';

/* const DateRangeEnum = [
  'TODAY',
  'LAST_2_DAY',
  'LAST_3_DAY',
  'THIS_WEEK',
  'LAST_2_WEEK',
  'LAST_3_WEEK',
  'THIS_MONTH',
  'LAST_3_MONTH',
  'LAST_6_MONTH',
  'THIS_YEAR',
  'LAST_2_YEAR',
]; */

const bankAccountActivities = (
  selectedVkns = [],
  bankAccountNo = 'D-123456786',
  // eslint-disable-next-line no-undef
  dateRange = LAST_2_YEAR,
  selectedBanks = [10],
  selectedBranches = [],
) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $bankAccountNo: String
        $dateRange: DateRangeEnum!
        $selectedBanks: [Int]
        $selectedBranches: [Int]
        $description: String
      ) {
        bankAccountActivityQuery {
          activities(
            selectedVkns: $selectedVkns
            bankAccountNo: $bankAccountNo
            dateRange: $dateRange
            selectedBanks: $selectedBanks
            selectedBranches: $selectedBranches
            description: $description
          ) {
            accountName
            accountNo
            amount
            bankCode
            bankIconLogo
            bankLogo
            bankName
            branchCode
            branchName
            currency
            date
            description
            iban
            id
            notViewed
            totalBalance
            transactionId
            vkn
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      bankAccountNo,
      dateRange,
      selectedBanks,
      selectedBranches,
    },
  };
};

export default bankAccountActivities;

/*

const response = {
  "data": {
    "bankAccountActivityQuery": {
      "activities": [
        {
          "accountName": "Demo Hesap - TRY",
          "accountNo": "D-123456786",
          "amount": "35000.00",
          "bankCode": 10,
          "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
          "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
          "bankName": "Ziraat Bankası A.Ş.",
          "branchCode": 2507,
          "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
          "currency": "TRY",
          "date": "2021-02-24T23:58:19.888954",
          "description": "2 Demo kullanıcı için oluşturulmuş üçüncü fake bir harekettir.",
          "iban": "TRTR670006200000000000000000",
          "id": 0,
          "notViewed": false,
          "totalBalance": "80000.00",
          "transactionId": "5de53613-e9c4-48ef-a557-6b473e4c783c",
          "vkn": "100000000"
        },
        {
          "accountName": "Demo Hesap - TRY",
          "accountNo": "D-123456786",
          "amount": "25000.00",
          "bankCode": 10,
          "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
          "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
          "bankName": "Ziraat Bankası A.Ş.",
          "branchCode": 2507,
          "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
          "currency": "TRY",
          "date": "2021-02-22T23:58:19.888947",
          "description": "2 Demo kullanıcı için oluşturulmuş ikinci fake bir harekettir.",
          "iban": "TRTR670006200000000000000000",
          "id": 0,
          "notViewed": false,
          "totalBalance": "45000.00",
          "transactionId": "c58501d7-3977-404e-a0ac-b2f4b3893d11",
          "vkn": "100000000"
        },
        {
          "accountName": "Demo Hesap - TRY",
          "accountNo": "D-123456786",
          "amount": "20000.00",
          "bankCode": 10,
          "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
          "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
          "bankName": "Ziraat Bankası A.Ş.",
          "branchCode": 2507,
          "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
          "currency": "TRY",
          "date": "2021-02-21T23:58:19.88884",
          "description": "2 Demo kullanıcı için oluşturulmuş fake bir harekettir.",
          "iban": "TRTR670006200000000000000000",
          "id": 0,
          "notViewed": false,
          "totalBalance": "20000.00",
          "transactionId": "fc8f4a11-44d6-4b03-a1b5-e1cb20af6eb9",
          "vkn": "100000000"
        }
      ]
    }
  }
}

*/
