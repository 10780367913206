import { gql } from '@apollo/client';

const userBanks = () => {
  return {
    query: gql`
      query {
        userQuery {
          userBanks {
            bankCode
            bankName
            iconLogo
            logo
            order
          }
        }
      }
    `,
    variables: {},
  };
};

export default userBanks;
