export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export const formattedOptions = (options) => {
  if (!Array.isArray(options)) return [];

  const optionsType = typeof options[0];
  if (optionsType === 'object') return options;

  return options.map((option) => ({
    label: option,
    value: stringToSlug(option),
  }));
};

export const getLabelFromValue = (options, value, multiSelect) => {
  if (!multiSelect) {
    if (!value || value === '') return null;

    const index = options.findIndex((item) => item.value === value);
    return index > -1 ? options[index]?.label : null;
  }

  const filteredItems = value.map((item) => {
    const index = options.findIndex((opt) => opt.value === item);
    return options[index]?.label;
  });

  return filteredItems;
};

export const getDefaultValues = (value, multiselect) => {
  return multiselect ? (value.length > 0 ? value : []) : value ? value : null;
};

export const getSelectedItemIndex = (cloneSelectedValues, selectedItem) => {
  return cloneSelectedValues.findIndex((selected) => selected === selectedItem);
};
